import { ViewImage } from '@atproto/api/dist/client/types/app/bsky/embed/images';
import { HeartIcon, MessagesSquareIcon, RepeatIcon, TagsIcon } from 'lucide-react';
import { Link } from 'react-router';
import { Avatar } from '~/components/Avatar';
import { PostCardImage } from '~/components/cards/PostCardImage';
import { Button } from '~/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';
import { NavBtnStyle } from '~/config/enums';
import { formatNumber } from '~/lib/numbers';
import { cn } from '~/lib/utils';
import { Post } from '~/services/posts/Post';
import RobinNavButton from '../robin/RobinNavButton';
import CardContainer from './CardContainer';

export interface PostCardProps {
  href?: string;
  avatar?: string;
  displayName: string;
  handle: string;
  timestamp: string;
  text: string;
  images: ViewImage[];
  isFiltered: boolean;
  onClick?: () => void;
  onMoreClick?: () => void;
  onAddToCollection: () => void;
  showMore?: boolean;
  showControllers?: boolean;
  showImpressions?: boolean;
  onLikeClicked?: () => void;
  onCommentClicked?: () => void;
  onRespostClicked?: () => void;
  post?: Post;
}

export default function PostCard({
  href,
  avatar,
  displayName,
  handle,
  timestamp,
  text,
  images: postImages,
  isFiltered,
  onMoreClick,
  onAddToCollection,
  showMore,
  showControllers = true,
  showImpressions = false,
  onCommentClicked,
  onLikeClicked,
  onRespostClicked,
  post,
}: PostCardProps) {
  const images = postImages.filter((image) => !!image.thumb);
  const content = (
    <CardContainer>
      <div className={cn('flex flex-row items-start justify-between gap-2 overflow-hidden ', isFiltered && 'opacity-20')}>
        {/* Avatar */}
        <Avatar placeHolderIcon="bluesky" avatarUrl={avatar} size={14} shape="circle" />
        {/* Card Info */}
        <div className="flex-grow truncate items-start justify-start">
          <p className="text-black robin-medium-16 select-auto">{displayName}</p>
          <span className="text-rf-gray-75 robin-medium-14 select-auto">{handle}</span>
        </div>
        <span className="flex-shrink-0 robin-medium-12 text-rf-gray-75 items-start">{timestamp}</span>
      </div>

      {/* Content */}
      <div className={cn('text-black robin-medium-14 break-words whitespace-pre-wrap select-auto', isFiltered && 'opacity-20')}>{text}</div>

      {/* Post Images */}
      {images && images.length > 0 && (
        <div
          className={cn('justify-center items-center w-full grid gap-2 overflow-hidden', isFiltered && 'opacity-20', {
            'grid-cols-1': images.length === 1,
            'grid-cols-2': images.length === 2,
            'grid-cols-6 grid-rows-6': images.length > 2,
            'aspect-square': images.length === 3 || (images.length === 1 && images[0].aspectRatio!.width / images[0].aspectRatio!.height < 1),
            'aspect-[14/9]': images.length === 3,
          })}
        >
          {images?.map((image, index) => {
            return (
              <div
                key={'img-' + index}
                className={cn('relative max-h-full h-full', {
                  'aspect-square': images.length === 2 || images.length === 4,
                  'row-span-6 col-span-4': index === 0 && images.length === 3,
                  'row-span-3 col-span-2': index !== 0 && images.length === 3,
                  'row-span-3 col-span-3': images.length === 4,
                  'flex items-start justify-start overflow-hidden': images.length === 1,
                  'h-full': images.length !== 1,
                  'w-full': images.length === 1 && image.aspectRatio && (image.aspectRatio?.width > image.aspectRatio?.height || image.aspectRatio?.width == image.aspectRatio?.height),
                })}
                style={images.length === 1 && image.aspectRatio && image.aspectRatio.width !== image.aspectRatio.height ? { aspectRatio: image.aspectRatio?.width / image.aspectRatio?.height } : {}}
              >
                <PostCardImage
                  image={image}
                  className={cn('rounded-lg object-cover', {
                    'max-w-full max-h-full ': images.length === 1,
                    'h-full w-full max-h-full': images.length !== 1,
                    'h-full': image.aspectRatio && image.aspectRatio.height > image.aspectRatio?.width,
                    'w-full': image.aspectRatio && image.aspectRatio?.width >= image.aspectRatio?.height,
                  })}
                />
              </div>
            );
          })}
        </div>
      )}
      {showControllers && (
        <div className="flex gap-2 flex-row justify-end items-center">
          <Button
            size="icon"
            // variant="secondary-destructive"
            variant="secondary"
            onClick={onAddToCollection}
          >
            <TagsIcon />
          </Button>
          {showMore && <RobinNavButton iconName="more" ariaLabel="More" onClick={onMoreClick} btnStyle={NavBtnStyle.Default} />}
        </div>
      )}
      {showImpressions && post && (
        <div className="flex justify-between w-full">
          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button size="icon" variant="ghost" className="w-auto gap-1" onClick={onCommentClicked}>
                  <MessagesSquareIcon />
                  <span className="text-xs">{formatNumber(post.impressions.comments)}</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>Comments</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  size="icon"
                  variant="ghost"
                  className={cn('w-auto gap-1 transition-all', {
                    'bg-rf-secondary/10 hover:bg-rf-secondary/20 focus:bg-rf-secondary/20': post.impressions.userLiked,
                    'text-rf-secondary': post.impressions.userLiked,
                  })}
                  onClick={onLikeClicked}
                >
                  <HeartIcon
                    className={cn('transition-all fill-transparent', {
                      'fill-rf-secondary': post.impressions.userLiked,
                    })}
                  />
                  <span className="text-xs">{formatNumber(post.impressions.likes)}</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>Like</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button size="icon" variant="ghost" className="w-auto gap-1" onClick={onRespostClicked}>
                  <RepeatIcon />
                  <span className="text-xs">{formatNumber(post.impressions.reposts)}</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>Repost</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      )}
    </CardContainer>
  );

  return href ? (
    <Link to={href} aria-label={`${displayName} - ${handle}`}>
      {content}
    </Link>
  ) : (
    <div>{content}</div>
  );
}
