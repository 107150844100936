import { ViewImage } from '@atproto/api/dist/client/types/app/bsky/embed/images';
import { FC, useCallback, useRef, useState } from 'react';
import { cn } from '~/lib/utils';

export const PostCardImage: FC<{ image: ViewImage; className?: string; loadingAnimation?: boolean }> = ({ image, className }) => {
  const [error, setError] = useState(false);
  const isLoading = useRef<boolean | undefined>(true);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const onLoad = useCallback(() => {
    isLoading.current = true;
    imageRef.current?.classList.remove('opacity-0');
    if (!imageRef.current?.classList.contains('opacity-100') || imageRef.current?.classList.contains('opacity-0')) imageRef.current?.classList.add('opacity-100');
  }, []);

  const onImageMounted = useCallback((el: HTMLImageElement) => {
    if (!imageRef.current) imageRef.current = el;
    if (!isLoading.current) {
      el?.classList.add('opacity-100');
      el?.classList.remove('opacity-0');
    }
    if (isLoading.current) {
      el?.classList.add('opacity-0');
      el?.classList.remove('opacity-100');
    }
  }, []);

  // useEffect(() => {
  //   // if after 10ms image is not loaded, so it's trying to download the image
  //   // so set the isLoading to true
  //   // we use this approach to avoid transition effect for cached images
  //   if (typeof isLoading === 'undefined') {
  //     const timeout = setTimeout(() => {
  //       setIsLoading(true);
  //     }, 10);
  //     return () => clearTimeout(timeout);
  //   }
  // }, [isLoading]);

  if (error) return <div className={cn(className, 'border border-gray-400')} style={image.aspectRatio?.width ? { aspectRatio: image.aspectRatio?.width / image.aspectRatio?.height } : {}} />;

  return (
    <>
      {<div className={cn('bg-skeleton border border-gray-200 absolute w-full h-full top-0 left-0 rounded-lg z-10', {})}></div>}
      <img
        src={image.thumb}
        alt={image.alt}
        ref={onImageMounted}
        onLoad={onLoad}
        style={image.aspectRatio && image.aspectRatio?.width !== image.aspectRatio?.height ? { aspectRatio: image.aspectRatio?.width / image.aspectRatio?.height } : {}}
        className={cn(className, 'relative z-20 border border-gray-200 transition-opacity duration-300 opacity-100', {
          'opacity-0': isLoading,
        })}
        onError={setError.bind(null, true)}
      />
    </>
  );
};
