export function formatNumber(num: number): string {
  const units = ['K', 'M', 'B', 'T'];
  let unitIndex = -1;

  while (num >= 1000 && unitIndex < units.length - 1) {
    num /= 1000;
    unitIndex++;
  }

  return unitIndex === -1 ? num.toString() : `${num.toFixed(1).replace(/\.0$/, '')}${units[unitIndex]}`;
}
